/* eslint-disable react/jsx-key */
import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "../../../functions/classNames";
import { DropdownButton, withDropdownBody } from "../../Dropdown";
import "./ProductsTabsHeader.css";

/**
 * Header with tab names
 * @param {Object} $
 * @param {Number} $.current
 * @param {Array[String]} $.tabNames - titles of the tabs
 * @param {Function} $.changeTab - changes tab
 */
export default function ProductsTabsHeader({ current, tabNames, changeTab }) {
  return (
    <div className="ProductsTabsHeader">
      {tabNames.map((title, index) => (
        <button key={title} className="unrecognizable">
          <h2
            className={classNames(
              "h1",
              "ProductsTabsHeader__title",
              current === index && "ProductsTabsHeader__title--active",
            )}
            onClick={() => changeTab(index)}
          >
            {title}
          </h2>
        </button>
      ))}
    </div>
  );
}

/**
 * Header with tab names
 * @param {Object} $
 * @param {Number} $.current
 * @param {Array[String]} $.tabNames - titles of the tabs
 * @param {Function} $.changeTab - changes tab
 */
export function TabsLinksHeader({ tabs }) {
  return (
    <div className="ProductsTabsHeader">
      {tabs.map(({ to, href, title, disabled, content }) => {
        if (!to && !href) {
          return (
            <DropdownButton
              className="ProductsTabsHeader__title"
              buttonClassName="OpenHours__popup"
              body={withDropdownBody(({ handleClose }) => (
                <div className="ProductsTabsHeader__PromoTools" onClick={handleClose}>
                  {content}
                </div>
              ))}
            >
              <div>{title}</div>
            </DropdownButton>
          );
        }
        return href ? (
          <a
            key={to || href}
            href={href}
            className={classNames(
              "ProductsTabsHeader__title",
              disabled && "ProductsTabsHeader__title--disabled",
            )}
          >
            {title}
          </a>
        ) : (
          <NavLink
            key={to}
            to={to}
            className={classNames(
              "ProductsTabsHeader__title",
              disabled && "ProductsTabsHeader__title--disabled",
            )}
            activeClassName="ProductsTabsHeader__title--active"
          >
            {title}
          </NavLink>
        );
      })}
    </div>
  );
}
