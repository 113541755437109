import React from "react";
import "./ReviewGroup.css";

/**
 * Renders group of reviews
 * @param {Object} $
 * @param {String} $.type - `"list"`, `"grid"`, `"scroll"` or `"empty"` (no wrapper added to children)
 * @param {Array[React::Element]} $.children - content of the component
 */
export default function ReviewGroup({ type, children }) {
  switch (type) {
    case "grid": {
      return (
        <div className="row ReviewGroup ReviewGroup--grid">
          {React.Children.map(children, child => (
            <div className="col-xs-12 col-md-4 ReviewGroup__item">{child}</div>
          ))}
        </div>
      );
    }
    case "scroll":
      return (
        <span className="Wrapper__overload">
          <div className="ReviewGroup ReviewGroup--scroll">
            {React.Children.map(children, child => (
              <div className="ReviewGroup__item">{child}</div>
            ))}
          </div>
        </span>
      );
    case "empty":
      return children;
    default: {
      return <div>{children}</div>;
    }
  }
}
