import React, { useState, Children, useEffect, useMemo } from "react";
import ProductsTabsArrows from "./ProductsTabsArrows";
import ProductsTabsHeader from "./ProductsTabsHeader";

/**
 * ProductsSlider with tabs at the top
 * @param {Object} $
 * @param {Number} $.amount - amount of tabs (will be shown as a info between arrows, nothing more)
 * @param {Array[String]} $.tabNames - will be displayed at the top (index must be same with suitable child's one)
 * @param {Array[React::Component]} $.children - slides in the component
 * @param {Boolean} $.header - show/hide header (`true` by default)
 * @param {Boolean} $.arrows - show/hide arrows
 * @param {Function} $.onBeforeChange - calls when current tab intended to change
 * @param {Function} $.onChanged - calls when current tab changed
 */
export default function ProductTabs({
  amount,
  tabNames = [],
  children,
  header = true,
  arrows,
  className,
  current: controlledCurrent,
  onBeforeChange = () => {},
  onChanged = () => {},
  syncWithURL = false,
}) {
  const [current, setCurrent] = useState(0);

  const changeTab = index => {
    onBeforeChange(index);
    setCurrent(index);
    onChanged(index);

    if (!arrows && children.length > 1 && index > children.length - 1) {
      setCurrent(children.length - 1);
    }
  };

  useEffect(() => {
    if (controlledCurrent) setCurrent(controlledCurrent);
  }, [controlledCurrent]);

  const currentChildren = useMemo(() => {
    if (controlledCurrent) return children;
    if (Array.isArray(children) && children.length > current) return children[current];
    return null;
  }, [controlledCurrent, children, current]);

  return (
    <div className={`ProductsTabs ${className || ""}`}>
      {header && <ProductsTabsHeader current={current} tabNames={tabNames} changeTab={changeTab} />}
      <div className="ProductsTabs__container">{currentChildren}</div>
      {arrows && amount > 1 && (
        <ProductsTabsArrows
          current={current}
          amount={Children.count(children)}
          possibleAmount={amount}
          changeTab={changeTab}
          syncWithURL={syncWithURL}
        />
      )}
    </div>
  );
}
