/**
 * Gets UserAgent in client and in SSR
 * @param {Express::Request} req - request from client with it's info
 * @return {UserAgent}
 */
export function getUserAgent(req) {
  if (req) {
    /**
     * If you are on the server and you get a 'req' property from your context
     * Getting the user-agent from the headers
     */
    return req.headers["user-agent"];
  }

  /**
   * if you are on the client you can access the navigator from the window object
   */
  return navigator.userAgent;
}

/**
 * From user agent decides if current device is mobile
 * SSR-safe
 * @param {UserAgent?} - browser UserAgent, if not given will try to reach it
 * @return {Boolean}
 */
export function isMobileUserAgent(userAgent) {
  return Boolean(
    userAgent.match(
      /Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|webOS|Windows Phone|WPDesktop/i,
    ),
  );
}
