import React, { useEffect, useRef } from "react";
import uuid from "react-uuid";
import { Trans } from "@lingui/macro";
import classNames from "../../../functions/classNames";
import Popup from "../../Popup";
import ReviewGroup from "../ReviewGroup";
import Review from "../Review";
import "./ReviewPopup.css";
import useOnScreen from "../../../functions/customHooks/useOnScreen";
import Spinner from "../../Spinner";

const defaultTitle = (
  <h2>
    <Trans>Reviews</Trans>
  </h2>
);

/**
 * Popup with reviews
 * @param {Object} $
 * @param {String?} $.className - additional CSS class for root element
 * @param {String?} $.theme - theme of the `Popup` component
 * @param {String} $.lang - language of reviews
 * @param {Number?} $.rating - average rating
 * @param {Number?} $.amount - total amount of reviews (including non-fetched)
 * @param {Number?} $.textLength - length of the short version of the text in `Review`
 * @param {Array[Object]} $.reviews - array with props for `Review` component describing set of reviews
 * @param {Array[React::Ref]} $.reviewRefs - array with empty refs will be set on popup children `Review` components
 * @param {Function?} $.onOpened - popup was opened
 * @param {Function?} $.onClosed - closed button was clicked on popup
 * @param {Boolean} $.open - if popup shown
 * @param {Boolean} $.showResponses - responces to reviews will be shown
 */
export default function ReviewPopup({
  className,
  reviewRefs = [],
  theme,
  lang,
  title = defaultTitle,
  reviews = [],
  amount,
  textLength,
  current,
  triggerElement,
  convertReviewFn,
  onPaginate = () => {},
  showResponses = () => {},
  onOpened = () => {},
  onClosed = () => {},
}) {
  /**
   * Indicate if the popup is fully scrolled
   */
  const endOfPopup = useRef(null);
  const isEndOfReviews = useOnScreen(endOfPopup);

  useEffect(() => {
    if (isEndOfReviews && amount > reviews.length) {
      onPaginate(current + 1);
    }
  }, [isEndOfReviews]);

  return (
    <Popup
      rendered
      className={classNames("ReviewPopup", className)}
      theme={theme}
      title={title}
      triggerElement={triggerElement}
      onClosed={onClosed}
      onOpened={onOpened}
    >
      <ReviewGroup type="list">
        {reviews.map((item, i) => {
          const review = convertReviewFn ? convertReviewFn(item) : item;

          return (
            <Review
              key={uuid()}
              {...review}
              lang={lang}
              convertReviewFn={convertReviewFn}
              className="ReviewPopup__review"
              textLength={textLength}
              shrinkResponses={!showResponses}
              {...(reviewRefs.length && reviewRefs[i] ? { rootRef: reviewRefs[i] } : {})}
            />
          );
        })}
      </ReviewGroup>
      <div ref={endOfPopup} className="ReviewPopup__end">
        {amount > reviews.length && <Spinner />}
      </div>
    </Popup>
  );
}
