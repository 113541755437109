import { FETCH_REVIEWS } from "../../stores/types";

const fetchReviews =
  ({ dispatch, reviewsNumber, replaceStore, lang, queryParams }) =>
  page =>
    dispatch({
      type: FETCH_REVIEWS,
      page,
      replace: replaceStore,
      per_page: reviewsNumber,
      lang,
      ...queryParams,
    });

export default fetchReviews;
