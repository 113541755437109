import React, { useState, useRef, useMemo } from "react";
import get from "lodash/get";
import uuid from "react-uuid";
import { Trans } from "@lingui/macro";
import classNames from "../../../functions/classNames";
import Button from "../../Button";
import isSSR from "../../../constants/env/ssr";
import ReviewPopup from "../ReviewPopup";
import ReviewGroup from "../ReviewGroup";
import Review from "../Review";
import "./ReviewPopupAdapter.css";

/**
 * Accumulating preview of reviews with extended info in popup
 * @param {Object} $
 * @param {String?} $.theme - theme of the `Review` component
 * @param {String?} $.popupTheme - theme of the `ReviewPopup` component
 * @param {String?} $.type - type of layout for showing preview set of reviews
 * @param {String} $.lang - language of the review
 * @param {Number} $.rating - average rating
 * @param {Number} current - current page with reviews number from `stores::reviews.current`
 * @param {Number} $.amount - amount of reviews (less than length of `reviews` array prop)
 * @param {Array[Object]} $.preview - array of props for `Review` component, will be shown by default in shrinked mode
 * @param {Array[Object]} $.reviews - array of props for `Review` component, will be shown in the detailing popup
 * @param {Function} $.onRequest - will be called when popup is opened if length of `reviews` array is less than value of `amount` prop
 * @param {Function} $.onPopupToggled - user toggled popup
 * @param {Function} $.onPaginate - will be called when popup is fully scrolled
 */
export default function ReviewPopupAdapter({
  theme,
  lang,
  title,
  popupTheme,
  type = "grid",
  preview = [],
  current,
  amount,
  passedReviews,
  rating,
  convertReviewFn,
  lineHeight,
  reviews = [],
  onRequest = () => {},
  onOpened = () => {},
  onPaginate = () => {},
  onFirstOpen = () => {},
}) {
  const [firstOpened, setFirstOpened] = useState(false);
  /**
   * Refs to reviews in popup
   * @type {Array[React::Ref]}
   */
  const reviewRefs = useMemo(() => preview.map(() => React.createRef()), [preview]);

  /**
   * Id of the review to scroll to when popup opened
   * @type {Number}
   */
  const responseId = useRef(null);

  /**
   * @param {Number?} id - id of the review for scrolling down to
   */
  const scrollToReview = (id = null) => {
    if (!reviews.length) onRequest();
    responseId.current = id;
  };

  /**
   * Triggers when popup was opened
   * If possible - scrolls down to the review with `responseId` id
   */
  const popupOpened = () => {
    if (!firstOpened) {
      // Trigger callback `onFirstOpen` when user opened popup for the first time
      onFirstOpen();
      setFirstOpened(true);
    }

    onOpened();
    scrollToReview();
    const id = responseId.current;

    if (id !== null && get(reviewRefs[id], "current.scrollIntoView")) {
      reviewRefs[id].current.scrollIntoView();
      responseId.current = null;
    }
  };

  return (
    <div className={classNames("ReviewPopupAdapter", `ReviewPopupAdapter--${type}`)}>
      <div className="ReviewPopupAdapter__preview">
        <ReviewGroup type={type}>
          {preview.map((item, i) => {
            const review = convertReviewFn ? convertReviewFn(item) : item;

            return (
              <Review
                key={uuid()}
                lineHeight={lineHeight}
                lang={lang}
                {...review}
                theme={theme}
                convertReviewFn={convertReviewFn}
                id={i}
                onResponseRequested={() => scrollToReview(i)}
                {...(type === "grid" ? { linesAmount: 3 } : { textLength: 18 })}
              />
            );
          })}
        </ReviewGroup>
      </div>
      {!isSSR &&
        ((amount && amount > (preview?.length || 0)) ||
          (passedReviews?.length || 0) > (preview?.length || 0)) && (
          <ReviewPopup
            showResponses
            className="ReviewPopupAdapter__popup"
            lang={lang}
            title={title}
            theme={popupTheme}
            convertReviewFn={convertReviewFn}
            rating={rating}
            reviews={reviews}
            reviewRefs={reviewRefs}
            amount={amount}
            current={current}
            triggerElement={
              <Button size="L" className="ReviewPopupAdapter__button" theme="card">
                <Trans>Show all reviews</Trans>
              </Button>
            }
            onPaginate={onPaginate}
            onOpened={popupOpened}
          />
        )}
    </div>
  );
}
